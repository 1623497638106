import React, { useState, useEffect, Fragment, useLayoutEffect } from "react";
import { Input, Pagination, Spin } from "antd";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import NoImage from "../../assets/images/No_Image.jpg";
import { useDispatch, useSelector } from "react-redux";
import chooseSettingsService from "../../services/choose-settings.service";
import {
  handleCompleteEarningYourPendantPage,
  handleHideCompleteEarningYourStudPage,
} from "../../actions/chooseYourEarning/chooseYourEarningActions";
import { useLocation, useNavigate } from "react-router-dom";
import { setGlobalFontFamily } from "../../actions/PreviewColor/previewColor";
import { useTranslation } from "react-i18next";


const ChooseYourSetting = (props) => {
  const { t } = useTranslation();
  const { Search } = Input;
  const search = useLocation().search;
  const navigate = useNavigate();
  const paramdealerId = new URLSearchParams(search).get("DealerID");
  const DiamondId = new URLSearchParams(search).get("DiamondId");
  const ischooseYourSetting = new URLSearchParams(search).get(
    "ischooseYourSetting"
  );
  const pageName = new URLSearchParams(search).get("pageName");
  const socialshapeFilters = new URLSearchParams(search).get("shapeFilters");
  const socialtxtCutlet = new URLSearchParams(search).get("txtCutlet");
  const socialtxtClarity = new URLSearchParams(search).get("txtClarity");
  const sociaColorPriority = new URLSearchParams(search).get(
    "intColorPriority"
  );
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const dispatch = useDispatch();
  const [dealerID, setDealerID] = useState();
  const [viewItem, setViewItem] = useState("list");
  const [pendantData, setPendantData] = useState([]);
  const [pendantDataLoading, setPendantDataLoading] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [callForPrice, setCallForPrice] = useState(false);
  const [showAllContent, setShowAllContent] = useState(true);

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });

  const previewcolor = useSelector((state) => state.previewColorReducer);

  useEffect(() => {
    if (ischooseYourSetting == "True") {
      if (paramdealerId) {
        setDealerID(paramdealerId);
        if (pageName == "Mined") {
          handlePendantBuilderLoadStud(paramdealerId, pageNo, pageSize, "");
        } else {
          handlePendantBuilderLoadStud(paramdealerId, pageNo, pageSize, "");
        }
        handleGetSocialDetails(paramdealerId);
        dispatch(handleHideCompleteEarningYourStudPage());
      }
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          setDealerID(loginDetails.dealerId);
          handlePendantBuilderLoadStud(
            loginDetails.dealerId,
            pageNo,
            pageSize,
            ""
          );
          handleGetSocialDetails(loginDetails.dealerId);
          dispatch(handleHideCompleteEarningYourStudPage());
        }
      }
    }
  }, []);

  const handlePendantBuilderLoadStud = async (id, PageNo, PageSize, sku) => {
    let inputData = {
      dealerID: id.toString(),
      jewelryCategory: "",
      pageSize: PageSize.toString(),
      currentPage: PageNo.toString(),
      orderBy: "",
      totalCarat: "",
      shape: "",
      countryTaxRate: "",
      countryCode: "",
      sku: sku,
    };
    setPendantDataLoading(true);
    try {
      await chooseSettingsService
        .PendantBuilderLoadStud(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData.dtProducts;
          let TotalCount = response.data.responseData.dtProducts.length;

          if (msg == "Success") {
            setPendantData(responseData);
            if (TotalCount > 0) {
              setTotalRowCount(responseData[0].intTotalRecords);
            } else {
              setTotalRowCount(0);
            }
            setPendantDataLoading(false);
          } else {
            setPendantData([]);
            setTotalRowCount(0);
            setPendantDataLoading(false);
          }
          setShowAllContent(false);
        })
        .catch((error) => {
          console.log(error);
          setPendantDataLoading(false);
          setShowAllContent(false);
        });
    } catch (error) {
      console.log(error);
      setPendantDataLoading(false);
      setShowAllContent(false);
    }
  };

  const handleGetSocialDetails = (id) => {
    try {
      let inputData = {
        dealerID: parseInt(id),
      };
      SelectYourDiamondService.GetsPendantBuilderOptiondetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData.length > 0) {
              setCallForPrice(responseData[0].callForPricependant);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChooseMounting = (details) => {
    let obj = details;
    dispatch(handleCompleteEarningYourPendantPage(obj));
    navigate(
      "/completeyourring" +
        `?iscompleteyourring=True&isselectyourdiamond=false&DealerID=${paramdealerId}&dealerLink=${obj.DealerID}&DiamondId=${DiamondId}&StyleNumber=${obj.StyleNumber}&GFInventoryID=${obj.GFInventoryID}&pageName=${pageName}&shapeFilters=${socialshapeFilters}&txtCutlet=${socialtxtCutlet}&txtClarity=${socialtxtClarity}&intColorPriority=${sociaColorPriority}`
    );
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleSearchCollection = async (e) => {
    handlePendantBuilderLoadStud(dealerID, 1, pageSize, e);
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handlePendantBuilderLoadStud(dealerID, page, pageSize, "");
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handlePendantBuilderLoadStud(dealerID, current, pageNewSize, "");
    }
  }

  useLayoutEffect(() => {
    if (paramdealerId) {
      handleGetColorsData(paramdealerId);
    } else {
      if (loginDetails) {
        if (loginDetails.dealerId) {
          handleGetColorsData(loginDetails.dealerId);
        }
      }
    }
  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "PendantBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              let tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
                dispatch(setGlobalFontFamily(newColorObj.chooseFont));
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  return (
    <Spin spinning={showAllContent} style={{ minHeight: "50vh" }}>
      {showAllContent == false && (
        <React.Fragment>
          <Wrapper>
            <div className="allprod_section jwlsrch__section app__preview choose__setting">
              <div className="row">
                <div className="col-md-12">
                  <div className="filter_section border-0">
                    <div className="col-md-12 desktop__version">
                      <div className="filt_optndiv">
                        <div className="gview__div">
                          <span className="font__bold mr-2 similar__text">
                            {TotalRowCount ? TotalRowCount : 0} {t("Setting")}
                          </span>
                          <span
                            className={
                              viewItem === "grid"
                                ? "fa fa-th grid_icon active"
                                : "fa fa-th grid_icon"
                            }
                            onClick={() => handleViewChange("grid")}
                          ></span>
                          <span
                            className={
                              viewItem === "list"
                                ? "fa fa-th-list list_icon active"
                                : "fa fa-th-list list_icon"
                            }
                            onClick={() => handleViewChange("list")}
                          ></span>
                        </div>

                        <div>
                          <Search
                            placeholder={t("Search Setting Stock #")}
                            onSearch={(e) => handleSearchCollection(e)}
                            allowClear
                            style={{ width: 250 }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mobile__version">
                      <div className="filt_optndiv">
                        <div className="gview__div">
                          <div className="row">
                            <div className="col-6 text-left-mobile">
                              <span className="font__bold mr-2 similar__text">
                                {TotalRowCount ? TotalRowCount : 0} {t("Setting")}
                              </span>
                            </div>
                            <div className="col-6 text-right-mobile">
                              <span
                                className={
                                  viewItem === "grid"
                                    ? "fa fa-th grid_icon active"
                                    : "fa fa-th grid_icon"
                                }
                                onClick={() => handleViewChange("grid")}
                              ></span>
                              <span
                                className={
                                  viewItem === "list"
                                    ? "fa fa-th-list list_icon active"
                                    : "fa fa-th-list list_icon"
                                }
                                onClick={() => handleViewChange("list")}
                              ></span>
                            </div>
                            <div className="col-12">
                              <div>
                                <Search
                                  placeholder={t("Search Setting Stock #")}
                                  onSearch={(e) => handleSearchCollection(e)}
                                  allowClear
                                  style={{ width: 250 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Fragment>
                      <Spin spinning={pendantDataLoading}>
                        {viewItem === "grid" && (
                          <div className="col-md-12 mt-4">
                            <div className="prod__section">
                              <div className="row">
                                {pendantData.map((x) => {
                                  return (
                                    <div className="col-lg-3 col-md-6 mb-3">
                                      <div className="prod__maindiv">
                                        <div className="prodimg__div my-hover-pointer">
                                          <img
                                            src={
                                              x.ImagePath == ""
                                                ? NoImage
                                                : x.ImagePath
                                            }
                                            alt=""
                                            onClick={() =>
                                              handleChooseMounting(x)
                                            }
                                          />
                                        </div>
                                        <div className="details__div">
                                          <span className="style__txt mb-2">
                                            {x.ProductName}
                                          </span>
                                          {callForPrice ? (
                                            <span className="subheading">
                                              Call For Price
                                            </span>
                                          ) : x.FinalPrice == "0" ||
                                            x.FinalPrice == "Call For Price" ? (
                                            " Call For Price"
                                          ) : (
                                            <span className="fw-bold">
                                              {x?.currencycode ?? ""}
                                              {x.CurrencySign}
                                              {parseInt(x.FinalPrice)
                                                .toFixed()
                                                .replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )}
                                            </span>
                                          )}
                                          <button
                                            className="primary-btn mt-3"
                                            onClick={() =>
                                              handleChooseMounting(x)
                                            }
                                          >
                                            {t("Choose This Mounting")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                        {viewItem === "list" && (
                          <div className="col-md-12 mt-4">
                            <div className="prod__section list__view">
                              <div className="row">
                                {pendantData.map((x) => {
                                  return (
                                    <div className="col-lg-12 mb-3">
                                      <div className="prod__maindiv row flex-row align-items-center">
                                        <div className="col-4 col-lg-3">
                                          <div className="prodimg__div my-hover-pointer">
                                            <img
                                              src={
                                                x.ImagePath == ""
                                                  ? NoImage
                                                  : x.ImagePath
                                              }
                                              alt=""
                                              onClick={() =>
                                                handleChooseMounting(x)
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-4 col-lg-3">
                                          <div className="details__div">
                                            <span className="style__txt">
                                              {x.ProductName}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-4 col-lg-3">
                                          <div className="details__div">
                                            {callForPrice ? (
                                              <span className="subheading">
                                                Call For Price
                                              </span>
                                            ) : x.FinalPrice == "0" ||
                                              x.FinalPrice ==
                                                "Call For Price" ? (
                                              " Call For Price"
                                            ) : (
                                              <span className="fw-bold">
                                                {x?.currencycode ?? ""}
                                                {x.CurrencySign}
                                                {parseInt(x.FinalPrice)
                                                  .toFixed()
                                                  .replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-12 text-right col-lg-3">
                                          <button
                                            className="primary-btn mobile-w-100"
                                            onClick={() =>
                                              handleChooseMounting(x)
                                            }
                                          >
                                            {t("Choose This Mounting")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </Spin>
                    </Fragment>
                    <div className="table__bottom__btn single__item">
                      <div className="">
                        <Pagination
                          current={pageNo}
                          pageSize={pageSize}
                          total={TotalRowCount}
                          showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} settings`
                          }
                          onChange={onChange}
                          onShowSizeChange={onShowSizeChange}
                          showSizeChanger="true"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </React.Fragment>
      )}
    </Spin>
  );
};

export default ChooseYourSetting;
